import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    background-color: #400f50; 
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 150px;
`

export const Titulo = styled.p`
    font-size: 120px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 0px;
    box-sizing: border-box;
`

export const Subtitulo = styled.p`
    font-size: 28px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 20px;
    box-sizing: border-box;
`

export const Img = styled.img`
    width: 300px;
`