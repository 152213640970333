import React from 'react';
import Logo from '../../../public/svg/logo-classi.svg';
import {
    Container,
    Titulo,
    Subtitulo,
    Img
} from './CustomError-styles'

const CustomError = ({ titulo = '', texto = '' }) => {
    return (
        <Container>
            <Img style={{ width: 300 }} src={Logo} />
            <Titulo>{titulo}</Titulo>
            <Subtitulo>{texto}</Subtitulo>
        </Container>
    )
}

export default CustomError;