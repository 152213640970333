import React from "react";

import Layout from '../components/Layout';
import CustomError from "../components/Error";

export default function Custom404() {
    return (
        <Layout>
            <CustomError titulo="Ops!" texto="Página não encontrada" />
        </Layout>
    );
}